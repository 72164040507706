import { graphql } from "gatsby"
import React from "react"

import HeaderWithTaglineOption from "./HeaderWithTaglineOption"

export const fragment = graphql`
  fragment HeaderWithTaglineOptionFragment on WpPage_Flexlayouts_FlexibleLayouts_HeaderWithTaglineOption {
    title
    description
    subTitle
    subDescription
    image {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            height: 400
            width: 500
            placeholder: NONE
          )
        }
      }
    }
    tags {
      tag
    }
    # config {
    #   padding {
    #     bottom {
    #       bottomDesktop
    #       bottomTablet
    #       bottomMobile
    #     }
    #     top {
    #       topDesktop
    #       topTablet
    #       topMobile
    #     }
    #   }
    # }
  }
`

export const ACFHeaderWithTaglineOption = ({ image, title, description, subTitle, subDescription, tags, config, breadcrumbData, isBrandPage }) => (
  <HeaderWithTaglineOption
    isBrandPage={isBrandPage}
    image={image}
    title={title}
    description={description}
    subTitle={subTitle}
    subDescription={subDescription}
    breadcrumbData={breadcrumbData}
    tags={tags}
    config={config}
  />
)
