// extracted by mini-css-extract-plugin
export var bgGrey100 = "HeaderWithTaglineOption-module--bg-grey-100--72ed5";
export var bgGrey150 = "HeaderWithTaglineOption-module--bg-grey-150--f9ec1";
export var bgGrey200 = "HeaderWithTaglineOption-module--bg-grey-200--b3e68";
export var bgGrey300 = "HeaderWithTaglineOption-module--bg-grey-300--b0088";
export var bgGrey400 = "HeaderWithTaglineOption-module--bg-grey-400--dacd4";
export var bgGrey500 = "HeaderWithTaglineOption-module--bg-grey-500--1e47e";
export var bgGrey600 = "HeaderWithTaglineOption-module--bg-grey-600--4716c";
export var bgGrey700 = "HeaderWithTaglineOption-module--bg-grey-700--85f9b";
export var bgGrey800 = "HeaderWithTaglineOption-module--bg-grey-800--8481e";
export var bgGrey900 = "HeaderWithTaglineOption-module--bg-grey-900--0f3d4";
export var h2 = "HeaderWithTaglineOption-module--h2--98a7d";
export var headerImgDesktopCls = "HeaderWithTaglineOption-module--headerImgDesktopCls--ef548";
export var headerImgMobileCls = "HeaderWithTaglineOption-module--headerImgMobileCls--eda7b";
export var imgPositionCls = "HeaderWithTaglineOption-module--imgPositionCls--804f8";
export var primaryTextContainerCls = "HeaderWithTaglineOption-module--primaryTextContainerCls--9a424";
export var secondaryTextContainerCls = "HeaderWithTaglineOption-module--secondaryTextContainerCls--7035e";
export var sliderCls = "HeaderWithTaglineOption-module--sliderCls--9581d";
export var sliderDesktopCls = "HeaderWithTaglineOption-module--sliderDesktopCls--53b36";
export var sliderItemCls = "HeaderWithTaglineOption-module--sliderItemCls--d83a7";
export var sliderMobileCls = "HeaderWithTaglineOption-module--sliderMobileCls--db008";
export var textBlockCls = "HeaderWithTaglineOption-module--textBlockCls--72c9f";
export var textGrey100 = "HeaderWithTaglineOption-module--text-grey-100--6df4a";
export var textGrey150 = "HeaderWithTaglineOption-module--text-grey-150--e95f3";
export var textGrey200 = "HeaderWithTaglineOption-module--text-grey-200--ebcc7";
export var textGrey300 = "HeaderWithTaglineOption-module--text-grey-300--331c8";
export var textGrey400 = "HeaderWithTaglineOption-module--text-grey-400--ba9df";
export var textGrey500 = "HeaderWithTaglineOption-module--text-grey-500--28f78";
export var textGrey600 = "HeaderWithTaglineOption-module--text-grey-600--c0e8a";
export var textGrey700 = "HeaderWithTaglineOption-module--text-grey-700--a4512";
export var textGrey800 = "HeaderWithTaglineOption-module--text-grey-800--6a63f";
export var textGrey900 = "HeaderWithTaglineOption-module--text-grey-900--a7f16";