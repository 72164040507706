import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Header } from "../../UI/Common"
import { Col, Container, Row } from "react-bootstrap"
import CheckGreenIcon from "../../../images/icon-cart-icon-check.inline.svg"
import {
  h2,
  headerImgMobileCls,
  headerImgDesktopCls,
  imgPositionCls,
  imgWrapperCls,
  sliderItemCls,
  sliderDesktopCls,
  sliderMobileCls,
  textBlockCls,
  secondaryTextContainerCls,
  primaryTextContainerCls,
} from "./HeaderWithTaglineOption.module.scss"
import { v4 as uuidv4 } from "uuid"
import Breadcrumb from "../../UI/Breadcrumb/Breadcrumb"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const HeaderWithTaglineOption = ({
  image,
  title,
  description,
  subTitle,
  subDescription,
  tags,
  config,
  breadcrumbData,
  isBrandPage=false
}) => {
  const { padding } = config || {}
  const slider = useRef(null)
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false,
  }

  return (
    <Header className="bg-light" padding={padding}>
      <>

        <Container className={primaryTextContainerCls}>
          <div className="row p-2">
            <div className="col-md-6 pb-4 pb-md-0">
            { isBrandPage === false && <Breadcrumb className="pl-1" data={breadcrumbData} />}
              <div className={textBlockCls}>
                <h1>{title}</h1>
                <p className="mb-0">{description}</p>
              </div>
            </div>
            <div className="position-relative col-md-6">
              <div className={imgPositionCls}>
                {image && (
                  <GatsbyImage
                    objectFit="contain"
                    className={`img-fluid position-absolute ${headerImgDesktopCls}`}
                    alt={image.altText}
                    image={getImage(image.localFile)}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>

        <div className={headerImgMobileCls}>
          {image && (
            <GatsbyImage
              className={`img-fluid mt-4`}
              alt={image.altText}
              image={getImage(image.localFile)}
            />
          )}
        </div>
        <div className="bg-white w-100">
        <Container className={secondaryTextContainerCls}>
          <div className="row pt-0 pt-md-4">
            <div className="col-md-6">
              <div className={textBlockCls}>
                <div className="pt-0 pt-md-3 pb-4">
                  <h2 className={`${h2} mb-3`}>{subTitle}</h2>
                  <p>{subDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {tags && (
          <div className="bg-light py-4">
            <Container>
              <Row className={sliderDesktopCls}>
                {tags.map(item => (
                  <Col key={uuidv4()}>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <CheckGreenIcon width={25} height={25} />
                      </div>
                      <div className="flex-grow-1 ml-3">{item.tag}</div>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className={`slick-override ${sliderMobileCls}`}>
                <Slider ref={slider} {...settings}>
                  {tags.map(item => {
                    return (
                      <div className={sliderItemCls} key={uuidv4()}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <CheckGreenIcon width={25} height={25} />
                          </div>
                          <div className="flex-grow-1 ml-3">{item.tag}</div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </Container>
          </div>
        )}
        </div>
      </>
    </Header>
  )
}

HeaderWithTaglineOption.propTypes = {
  text: PropTypes.arrayOf(PropTypes.object),
  tagline: PropTypes.arrayOf(PropTypes.object),
  padding: PropTypes.object,
}

export default HeaderWithTaglineOption
